import { render, staticRenderFns } from "./OrderStatus.vue?vue&type=template&id=0e8ca920&scoped=true&"
import script from "./OrderStatus.vue?vue&type=script&lang=js&"
export * from "./OrderStatus.vue?vue&type=script&lang=js&"
import style0 from "./OrderStatus.vue?vue&type=style&index=0&id=0e8ca920&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e8ca920",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconStatusComplete: require('/opt/build/repo/components/icons/IconStatusComplete.vue').default,IconStatusActive: require('/opt/build/repo/components/icons/IconStatusActive.vue').default,IconStatusInactive: require('/opt/build/repo/components/icons/IconStatusInactive.vue').default})
