
  import { mapState } from 'vuex'
  import moment from 'moment'
  
  export default {
    data() {
      return {
        statusChecker: null,
        arrived: false
      }
    },
    mounted() {
      if (this.activeOrder) {
        this.statusChecker = setInterval(this.getOrderStatus, 30000)
      }
      // Clear active order if it's more than a day old
      if (this.activeOrder) {
        if (this.activeOrder.status == 'Completed' && moment().diff(moment(this.activeOrder.time.ready, 'YYYYMMDD HH:mm'), 'days') > 0) {
          this.$store.commit('setActiveOrder', null)
        }
      }
    },
    destroyed() {
      clearInterval(this.statusChecker)
    },
    computed: {
      ...mapState(['activeOrder']),
      time() {
        console.log(this.activeOrder)
        return moment(this.activeOrder.time.ready, 'YYYYMMDD HH:mm').format('h:mm A • M/D/YY')
      }
    },
    methods: {
      async getOrderStatus() {
        if (this.activeOrder && this.activeOrder.id) {
          const orderStatus = await this.$api.getOrderStatus(this.activeOrder.id)
          this.$store.commit('setActiveOrder', orderStatus)
        }
      },
      async setArrival() {
        clearInterval(this.statusChecker)
        try {
          await this.$api.setArrival(this.$route.query.id)
          this.arrived = true
        } catch (error) {
          console.error(error)
        }
      },
    }
  }
